import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'

export default function Layout({ children }) {
  return (
    <>
      <div className='page grid grid-rows-layout min-h-full'>
        <Navbar />

        <main className='container mb-8'>{children}</main>
        <Footer />
      </div>
    </>
  )
}
