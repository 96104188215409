import React from 'react'
// import { StaticImage } from 'gatsby-plugin-image'
import Logo from '../svg/marinko-zic_logo.svg'
import Koalicija from '../svg/marinko-zic_koalicija.svg'

export default function Footer({ children }) {
  return (
    <>
      <section className='footer-wrapper bg-brand text-white py-3'>
        <footer className='container text-sm flex flex-row justify-center items-center'>
          {/* <StaticImage
            src='../images/marinko-logo-min-trans.png'
            alt='Marinko Žic logo'
            placeholder='blurred'
            quality='100'
            imgClassName='p-2'
            height='80'
          />
          <StaticImage
            src='../images/marinko-koalicija.png'
            alt='Marinko Žic logo'
            placeholder='blurred'
            quality='100'
            imgClassName='p-2'
          /> */}
          <Logo className='flex-shrink max-w-max' />
          <Koalicija className='flex-shrink max-w-max' />
        </footer>
      </section>
    </>
  )
}
